<template lang="html">
  <div class="app-profile">
    <div class="main-container" v-if="info">
      <div class="h2">个人资料</div>
      <div class="text-help">
        账户基础信息,如您的名字头像等,用于21世纪房车提供的网站、手机APP等服务。
      </div>
      <div class="form-list-group">
        <router-link :to="{ name: 'ProfileAvatar' }" tag="div" class="form-list-item">
          <div class="list-item-label">头像</div>
          <div class="list-item-body">
            <div class="p-avatar">
              <img :src="info.avatar + '_avatar'" alt="" />
            </div>
          </div>
        </router-link>
        <router-link :to="{ name: 'ProfileNickname' }" tag="div" class="form-list-item has-more">
          <div class="list-item-label">昵称</div>
          <div class="list-item-body">
            {{info.nickname}}
          </div>
        </router-link>
        <router-link :to="{ name: 'ProfileSex' }" tag="div" class="form-list-item has-more">
          <div class="list-item-label">性别</div>
          <div class="list-item-body">
            {{info.sex === '1' ? '男' : (info.sex === '2' ? '女' : '未知')}}
          </div>
        </router-link>
        <router-link :to="{ name: 'ProfileBirthday' }" tag="div" class="form-list-item has-more">
          <div class="list-item-label">生日</div>
          <div class="list-item-body">
            {{info.birthday}}
          </div>
        </router-link>
      </div>
      <div class="h2">账户安全</div>
      <div class="form-list-group">
        <router-link :to="{ name: 'ProfilePassword' }" tag="div" class="form-list-item has-more">
          <div class="list-item-label">密码</div>
          <div class="list-item-body">
            ••••••••
          </div>
        </router-link>
        <router-link :to="{ name: 'ProfileTel' }" tag="div" class="form-list-item has-more">
          <div class="list-item-label">绑定手机</div>
          <div class="list-item-body">
            {{info.tel}}
          </div>
        </router-link>
        <div class="form-list-item">
          <div class="list-item-label">绑定微信</div>
          <div class="list-item-body">
            {{info.is_wx ? '已绑定' : '未绑定'}}
          </div>
        </div>
        <div class="form-list-item has-more">
          <div class="list-item-label">注销账号</div>
          <div class="list-item-body">
          </div>
        </div>
      </div>
      <div class="btn-group">
        <button type="button" @click="logout" class="logout-btn">退出登录</button>
      </div>
      <div class="p-footer">
        2002-2020 &copy; 21世纪房车 版权所有
      </div>
    </div>
  </div>
</template>

<script>
import { AccountService } from '../../service/AccountService';
import { AccountFactory } from '../../factory/AccountFactory';

export default {
  data: () => ({
    info: null,
  }),
  methods: {
    async getInfo() {
      const res = await AccountService.getUserInfo();
      this.info = res.data.data;
    },
    logout() {
      AccountFactory.logout();
      this.$router.replace({ name: 'Home' });
    },
  },
  created() {
    this.getInfo();
  },
};
</script>

<style lang="css" scoped>
@import '../../css/profile.css';
.form-list-group {
  display: block;
  padding: 10px 0;
}
.form-list-item {
  padding: 18px 15px 18px 0;
  font-weight: 500;
  display: flex;
  border-bottom: 1px solid #F1F3F6;
  position: relative;
  transition: all .3s ease;
}
.form-list-item.has-more {
  cursor: pointer;
}
.form-list-item.has-more::after {
  position: absolute;
  top: 50%;
  transform: translateY(-6px);
  right: 0;
  content: ' ';
  background-color: #fff;
  width: 12px;
  height: 12px;
  background-image: url(https://img01.wanfangche.com/assets/images/icons/icon-arrow-right-muted.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.form-list-item.has-more:hover {
  background-color: #fafafa;
}
.list-item-label {
  width: 100px;
}
.list-item-body {
  flex: 1;
}
.p-avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 4px 4px;
  float: right;
}
.p-avatar > img {
  width: 100%;
}
.btn-group {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin: 20px 0;
}
.logout-btn {
  padding: 15px 15px;
  width: 100%;
  display: block;
  background-color: #31C27C;
  color: #FFF;
  font-weight: 500;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
.p-footer {
  font-size: 12px;
  margin: 40px 0;
  text-align: center;
}
</style>

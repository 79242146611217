<template>
  <transition name="fadeIn">
    <div class="">
      <div class="profile-header">
        <div class="logo">21世纪房车</div>
      </div>
      <div class="app-container">
        <div class="" v-if="isLoggedin">
          <profile-view />
        </div>
        <div class="home" v-show="!isLoggedin">
          <div class="welcome-block">
            <img
              src="https://img01.wanfangche.com/public/upload/202004/02/5e855905b33b0.png?x-oss-process=style/large"
              class="wl-logo"
            />
            <div class="welcome-body">
              <div class="wl-title">欢迎来到21世纪房车</div>
              <div class="">使用现有账户登录,或注册一个21世纪房车账户</div>
              <div class="">获取最新房车露营资讯</div>
              <div class="wl-btns">
                <router-link class="wl-btn" :to="{ name: 'Login' }"
                  >登录</router-link
                >
              </div>
              <router-link class="link" :to="{ name: 'Regist' }"
                >注册一个新账户</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileView from "./profile/index.vue";

export default {
  components: {
    ProfileView,
  },
  name: "Home",
  data: () => ({}),
  computed: {
    ...mapGetters(["isLoggedin"]),
  },
  created() {
    if (this.isLoggedin) {
      this.$router.replace({ name: "ProfileHome" });
    } else {
      this.$router.replace({ path: "/login" });
    }
  },
  methods: {
    init() {},
  },
};
</script>

<style type="css" scoped>
.home {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  line-height: 2em;
}
.welcome-block {
  width: 345px;
  margin: 20px auto;
  display: block;
  text-align: center;
}
.welcome-body {
}
.wl-logo {
  width: 100px;
}
.wl-title {
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0;
}
.wl-btns {
  margin: 20px 0;
}
.wl-btn {
  padding: 8px 30px;
  border-radius: 4px 4px;
  border: 1px solid #31c27c;
  margin-right: 10px;
  font-size: 14px;
  line-height: 1.5em;
  color: #fff;
  background-color: #31c27c;
  font-weight: 500;
}
</style>
